import checkItemRow from './_checkItemRow.js'
import form from '@/templates/shared/_form.js'

export default class {
  static spec(card) {
    let sections
    if (card.shapeUp.scopeCardId) {
      const checklists = $tpu.r.checklists.findAllByCardId(card.id) || []
      sections = checklists.map((checklist) => {
        return this.checklistSection(checklist, card)
      })
      sections.push(this.placeholderSection(card))
    } else {
      sections = []
    }

    return {
      "view": "panels/list",
      "width": "matchParent",
      "styleClasses": ["checklist"],
      "sections": sections
    }
  }

  static checklistSection(checklist, card) {
    const rows = checklist.checkItems.map((checkItem) => {
      return checkItemRow.spec(checkItem, checklist, card)
    })

    const newItemMode = $tpu.editing.newItemChecklistId == checklist.id

    return {
      "header": {
        "padding": { top: 30, bottom: 6 },
        "styleClasses": ["list-header"],
        "childViews": [
          {
            "view": "hr",
            "width": "matchParent",
          },
          this.checklistTitle(checklist, card),
          {
            "view": "hr",
            "width": "matchParent",
          }
        ]
      },
      "rows": rows,
      "footer": {
        "padding": { left: 32, top: 6 },
        "childViews": [
          newItemMode ? this.editableFooter(checklist) : this.readOnlyFooter(checklist)
        ]
      },
    }
  }

  static checklistTitle(checklist, card) {
    const editChecklistMode = $tpu.editing.checklistId == checklist.id
    return {
      "view": "panels/split",
      "padding": { "top": 4, "bottom": 4 },
      "styleClasses": ["glib-clickable"],
      "width": "matchParent",
      "left": {
        "width": "matchParent",
        "height": "matchParent",
        "childViews": [
          {
            "view": "panels/horizontal",
            "width": "matchParent",
            "height": "matchParent",
            "align": "middle",
            "padding": { "top": 4, "left": 6, "right": 4 },
            "childViews": [
              {
                "view": "icon",
                // "styleClasses": $tpu.styles.iconButtonClasses(),
                "material": {
                  "name": $tpu.keys.icnChecklist(),
                  "size": 18
                }
              },
              {
                "view": "spacer",
                "width": 4,
              },
            ]
          }
        ]
      },
      "center": {
        "width": "matchParent",
        "height": "matchParent",
        "childViews": [
          editChecklistMode ? this.editableTitle(checklist) : this.readOnlyTitle(checklist),
        ]
      },
      "right": {
        "childViews": editChecklistMode ? [] : this.readOnlyButtons(checklist, card),
      },
    }
  }

  /// Header

  static readOnlyTitle(checklist) {
    return form.readOnlyTitle(checklist.name, {
      checklistId: checklist.id
    })
  }

  static readOnlyButtons(checklist, card) {
    return [
      {
        "view": "panels/horizontal",
        "childViews": [
          {
            "view": "button",
            // "styleClasses": ["icon", "show-on-hover"],
            "styleClasses": $tpu.styles.iconButtonClasses().concat(["show-on-hover"]),
            "icon": { "material": { name: $tpu.keys.icnDestroy() } },
            "onClick": {
              "action": "sheets/select",
              "message": "This action cannot be undone. Are you sure?",
              "buttons": [
                {
                  "text": "Yes, delete permanently",
                  "onClick": {
                    "action": "commands/custom",
                    "name": "checklists/destroy",
                    "properties": {
                      "checklistId": checklist.id,
                      "cardId": card.id,
                    }
                  },
                  "type": "button"
                }
              ]
            }
          },
        ]
      }
    ]
  }

  static editableTitle(checklist) {
    return form.editableTitle(checklist.name, null, {
      "action": "commands/custom",
      "name": "checklists/update",
      "properties": {
        "checklistId": checklist.id
      }
    })
  }

  ///

  /// Footer

  static readOnlyFooter(checklist) {
    return form.readOnlyButton("Add an item", {
      "newItemChecklistId": checklist.id
    })
  }

  static editableFooter(checklist) {
    return form.editableTitle(null, "Add an item", {
      "action": "commands/custom",
      "name": "checkItems/create",
      "properties": {
        "checklistId": checklist.id
      }
    })
  }

  ///

  static placeholderSection(card) {
    return {
      "header": {
        "padding": { top: 30, bottom: 6 },
        "childViews": [
          {
            "view": "hr",
            "width": "matchParent",
          },
          {
            "view": "spacer",
            "height": 4,
          },
          this.placeholderHeader(card),
          {
            "view": "spacer",
            "height": 4,
          },
          {
            "view": "hr",
            "width": "matchParent",
          }
        ]
      }
    }
  }

  static placeholderHeader(card) {
    const newChecklistMode = $tpu.editing.newChecklistCardId == card.id

    return {
      "view": "panels/split",
      "width": "matchParent",
      "left": {
        "width": "matchParent",
        "height": "matchParent",
        "childViews": [
          {
            "view": "panels/horizontal",
            "width": "matchParent",
            "height": "matchParent",
            "align": "middle",
            "padding": { "top": 4, "left": 6, "right": 4 },
            "childViews": [
              {
                "view": "icon",
                "material": {
                  "name": $tpu.keys.icnChecklist(),
                  "size": 18
                }
              },
              {
                "view": "spacer",
                "width": 4,
              },
            ]
          }
        ]
      },
      "center": {
        "width": "matchParent",
        "height": "matchParent",
        "childViews": [
          newChecklistMode ? this.editablePlaceholderSection(card) : this.readOnlyPlaceholderSection(card)
        ]
      }
    }

  }

  static readOnlyPlaceholderSection(card) {
    return form.readOnlyButton("Add a checklist", {
      newChecklistCardId: card.id
    })
  }

  static editablePlaceholderSection(card) {
    return form.editableTitle("Checklist", "Add a checklist", {
      "action": "commands/custom",
      "name": "checklists/create",
      "properties": {
        "cardId": card.id
      }
    })
  }
}
